<template>
  <!-- 卡券 -->
  <div id="app">
    <div class="center-tit">
      我的卡券<span>/My Coupon</span>
      <ul>
        <li
          v-for="(item, index) in tabList"
          :key="index"
          :class="idx == index ? 'active' : ''"
          @click="tabNav(index)"
        >
          {{ item.name }}
        </li>
      </ul>
    </div>
    <div class="list-con">
      <div v-for="(item, index) in list" :key="index" class="list">
        <div v-if="idx == item.paystate">
          <div
            :class="[
              item.paystate == 2 ? 'coupon-bg2' : '',
              item.paystate == 3 ? 'coupon-bg3' : '',
            ]"
            class="coupon-bg"
          >
            <h2><span>¥</span>50</h2>
            <p>有效期截止至：2020.02.22单次购买任意一门小课使用</p>
          </div>
          <div class="coupon">
            <label>适用范围：</label
            >北美求职速成班/北美SDE算法速成班/北美SDE求职旗舰班
            LeetCode题视频讲解/面试算法基础班/刷题班/面向对象系统设计班
          </div>
          <div class="coupon gray">
            <label>使用说明：</label
            >内容内容内容内容内容内容内容内容内容内容内容内容内容内容内
            容内容内容内容内容内容内容内容内容内容内容内容
          </div>
          <div class="coupon gray">
            <label>有效日期：</label>2020.02.22-2020.03.22
          </div>
          <a :class="item.paystate == 2 || item.paystate == 3 ? 'disable' : ''"
            >去使用</a
          >
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      idx: 0,
      stateIndex: 0,
      tabList: [
        {
          name: "全部",
          checked: true,
        },
        {
          name: "未使用",
          checked: false,
        },
        {
          name: "已使用",
          checked: false,
        },
        {
          name: "已失效",
          checked: false,
        },
      ],
      list: [
        // {
        //   title: "Flutter从入门到进阶 实战携程网App",
        //   price: "500",
        //   paystate: 0,
        // },
        // {
        //   title: "Flutter从入门到进阶 实战携程网App",
        //   price: "230",
        //   paystate: 1,
        // },
        // {
        //   title: "Flutter从入门到进阶 实战携程网App",
        //   price: "400",
        //   paystate: 2,
        // },
        // {
        //   title: "Flutter从入门到进阶 实战携程网App",
        //   price: "280",
        //   paystate: 0,
        // },
        // {
        //   title: "Flutter从入门到进阶 实战携程网App",
        //   price: "620",
        //   paystate: 0,
        // },
        // {
        //   title: "Flutter从入门到进阶 实战携程网App",
        //   price: "440",
        //   paystate: 2,
        // },
        // {
        //   title: "Flutter从入门到进阶 实战携程网App",
        //   price: "440",
        //   paystate: 3,
        // },
      ],
    };
  },
  watch: {},
  created() {},
  mounted() {
    this.path = this.$route.path;
  },
  methods: {
    tabNav(index) {
      this.tabList.forEach((element, i) => {
        if (i == index) {
          element.checked = true;
        } else {
          element.checked = false;
        }
      });
      this.idx = index;
    },
  },
};
</script>

<style>
</style>
